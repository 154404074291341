@import '@royalaholddelhaize/design-system-pantry-web/utils/mq';

dialog[open].modal  {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
    border-radius: 0;
    min-height: 300px;

    @include mq($until: small) {
        height: 100%;
    }

    @include mq($from: medium) {
        max-width: 496px;
        border-radius: var(--border-radius-core-l);
    }
}

.modalHeader {
    padding: var(--spacing-core-3);
}

.action {
    padding-top: var(--spacing-core-4);
    border-top: var(--border-width-core-regular) solid #e0e0e0;
}

.modalContent {
    flex-grow: 1;
    overflow-y: scroll;
    scrollbar-width: thin;
}

.beppie {
    width: 100%;
    display: flex;
    justify-content: center;

    img {
        width: 280px;
        height: auto;
    }
}

.additionalDescription {
    color: var(--color-foreground-secondary-default);
    padding: var(--spacing-core-3) 0;

    a {
        color: var(--color-action-primary-default);
        text-decoration: underline;
    }
}

.row {
    padding-bottom: var(--spacing-core-4)
}
