@import '@royalaholddelhaize/design-system-pantry-web/utils/mq';

.root {
    display: flex;
    flex-direction: column;
    gap: var(--sizing-core-8);
}

.card {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    gap: var(--sizing-core-2);
    color: var(--color-text-primary-default);
}

.carousel {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--sizing-core-4);
}

.title {
    font-size: var(--sizing-core-6);
    line-height: var(--sizing-core-8);
}

.cta {
    color: var(--color-action-primary-default);
    font-size: 1.125rem;
    line-height: 1;
    font-weight: bold;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}
